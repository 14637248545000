<template>
  <div class="event">
    <div class="event__first-line">
      <div class="event__type">
        <div class="event__icon-container">
          <ion-icon
            slot="icon-only"
            :src="`assets/icon/${itemIcon}-mark.svg`"
            class="event__icon"
          />
        </div>
        <ion-text class="event__name">{{ event.name }}</ion-text>
      </div>
      <ion-text class="event__date">{{ date }}</ion-text>
      <ion-icon
        v-if="isCloseButtonRequired"
        color="primary"
        src="assets/icon/close.svg"
        @click="emits(EventNamesEnum.OnClose)"
        class="close-btn"
      />
    </div>
    <ion-text class="event__description">{{ event.description }}</ion-text>
  </div>
</template>

<script setup lang="ts">
import { Event, JobGroupsEnum, JobTypesEnum } from '@/gql/graphql';
import { IonText, IonIcon } from "@ionic/vue";
import { computed } from 'vue';
import dayjs from "dayjs";
import { EventNamesEnum } from '@/const/events';

const props = defineProps<{
  event?: Event;
  isCloseButtonRequired?: boolean;
}>();

const emits = defineEmits<{
  (e: EventNamesEnum.OnClose): void;
}>();

const date = computed(() => props.event?.pretty_date?.split(" ")[0].length >= 5 ? dayjs(props.event?.created_at).format('HH:mm') : props.event?.pretty_date)

const itemColor = computed(() => {
  switch (props.event?.group) {
    case JobGroupsEnum.Documents:
      return '#732C2C';
    case JobGroupsEnum.Feedbacks:
      return '#F6AE2D';
    case JobGroupsEnum.JobPosted:
      return '#33658A';
    case JobGroupsEnum.MyWork:
      return '#C5DCA0';
    case JobGroupsEnum.Subscriptions:
      return '#C578A0';
    case JobGroupsEnum.Else:
      return '#333333';
    default:
      return 'black'
  }
});

const itemIcon = computed(() => {
  if(props.event?.name === 'SDCN update alert!') {
    return 'info'
  } else {
    switch (props.event?.type) {
      case JobTypesEnum.Info:
        return 'info';
      case JobTypesEnum.Negative:
        return 'cross';
      case JobTypesEnum.Positive:
        return 'check';
      default:
        return ''
    }
  }
});
</script>

<style lang="scss" scoped>
.event {
  --background: var(--ion-color-white);
  --border-radius: 10px;
  --inner-border-width: 0;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-light-grey);
  padding: 10px;
  width: 100%;
  position: relative;

  &__first-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  &__type {
    display: flex;
    align-items: center;
  }

  &__icon-container {
    height: 24px;
    width: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    position: relative;
    background-color: v-bind(itemColor);
  }

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--ion-color-white);
  }

  &__name {
    color: var(--ion-color-dark);
    font-family: var(--ion-font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
    margin-left: 10px;
  }

  &__date {
    color: var(--ion-color-grey);
    font-family: var(--ion-font-family);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  &__description {
    color: var(--ion-color-dark);
    font-family: var(--ion-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  .close-btn {
    cursor: pointer;
    top: 20px;
    right: 16px;
    height: 25px;
    display: block;
    min-width: 25px;
    --icon-font-size: 44px;
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
    --icon-padding-bottom: 0;
    --icon-padding-end: 0;
    --icon-padding-start: 0;
    --icon-padding-top: 0;
    --min-height: 16px;
    --min-width: 16px;
    z-index: 5;
    :hover {
      opacity: .8;
    }
  }
}
</style>