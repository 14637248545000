import OneSignal from 'onesignal-cordova-plugin';
import { redirectToEntityPage } from '@/helpers/redirectToPageByEntity';

export default class OneSignalHandler {
  public init(): void {
    OneSignal.setLogLevel(6, 0);
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId(process.env.VUE_APP_ONE_SIGNAL_APP_ID);
    OneSignal.setNotificationOpenedHandler(function (jsonData) {
      redirectToEntityPage(jsonData.notification.additionalData as any);
    });
    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });
  }

  public setExternalId(externalId: string): void {
    OneSignal.setExternalUserId(externalId);
  }
}