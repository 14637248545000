import { initializeApollo } from "@/apollo";
import { RouteNamesEnum } from "@/const/routes";
import { EventRedirectTypesEnum, JobGroupsEnum, JobStatuses, JobTypesEnum, OnFlightTypeUpdates } from "@/gql/graphql";
import { Job } from "@/graphql/queries/job";
import router from "@/router";
import StorageService from "@/helpers/storageService";
import { computed } from "vue";
import { useUserStore } from "@/modules/General/store/userStore";
import { GetDocumentsByType } from "@/graphql/queries/getDocumentsByType";
import { isMobile } from "./isMobile";
import { AppUpdate } from "@capawesome/capacitor-app-update";

const user = computed(() => (useUserStore() as any).user);

const openAppStore = async () => {
  await AppUpdate.openAppStore();
};

export const redirectToEntityPage = async (event: any) => {
  if((event?.type === OnFlightTypeUpdates.AppUpdate || event?.name === 'SDCN update alert!') && !isMobile.value) {
    router.push({name: RouteNamesEnum.Help, query: { clear_cache_guide: 'open'}})
  } else if((event?.type === OnFlightTypeUpdates.AppUpdate || event?.name === 'SDCN update alert!') && isMobile.value) {
    openAppStore()
  } else {
    const currentEvent = {
      entity: event?.redirect_entity || event?.entity,
      role: event?.group || event?.role,
      id: event?.redirect_id || event?.id
    }

    if(currentEvent?.role === JobGroupsEnum.ChatMessage && currentEvent?.entity === EventRedirectTypesEnum.Job) {
      router.push({
        name: RouteNamesEnum.Chat,
        params: { id: currentEvent?.id }
      });
    }
  
    if(currentEvent?.role === JobGroupsEnum.Else && currentEvent?.entity === EventRedirectTypesEnum.Job) {
      router.push({
        name: RouteNamesEnum.BrowseJobs,
      });
    }
  
    if(currentEvent?.role === JobGroupsEnum.Documents && currentEvent?.entity === EventRedirectTypesEnum.Document) {
      if(event?.type === JobTypesEnum.Negative) {
        await initializeApollo().query({
          query: GetDocumentsByType,
          variables: { type_id: currentEvent?.id }
        })
          .then((response) => {
            StorageService.setItem('documents', response?.data?.getDocumentsByType)
            router.push({
              name: RouteNamesEnum.DocumentList,
              params: { type: currentEvent?.id }
            });
          })
      }
    }
    
    if(currentEvent?.role === JobGroupsEnum.JobPosted && currentEvent?.entity === EventRedirectTypesEnum.Bid) {
      router.push({
        name: RouteNamesEnum.Bids,
        params: { id: currentEvent?.id }
      });
    }
  
    if(currentEvent?.role === JobGroupsEnum.MyWork && currentEvent?.entity === EventRedirectTypesEnum.Job) {
      await initializeApollo().query({
        query: Job,
        variables: { id: currentEvent?.id }
      })
        .then((response) => {
          const freshUser = StorageService.getUser();
          if(response?.data?.job?.status === JobStatuses.Active || response?.data?.job?.bid_user?.id === freshUser.id) {
            router.push({
              name: RouteNamesEnum.OpenWork,
              params: { id: currentEvent?.id }
            });
          }
        })
    }
  
    if(currentEvent?.role === JobGroupsEnum.Feedbacks) {
      const redirect_id = currentEvent?.id ? currentEvent?.id : event?.description?.split('#')[1];
      await initializeApollo().query({
        query: Job,
        variables: { id: redirect_id }
      })
        .then((response) => {
          if(response?.data?.job?.bid_user?.id === user.value.id) {
            router.push({
              name: RouteNamesEnum.OpenWork,
              params: { id: redirect_id }
            });
          } else if(response?.data?.job?.user?.id === user.value.id) {
            router.push({
              name: RouteNamesEnum.OpenJob,
              params: { id: redirect_id }
            });
          }
        })
    }
  
    if(currentEvent?.role === JobGroupsEnum.JobPosted && currentEvent?.entity === EventRedirectTypesEnum.Pod) {
      await initializeApollo().query({
        query: Job,
        variables: { id: currentEvent?.id }
      })
        .then((response) => {
          router.push({
            name: response?.data?.job?.pod?.is_electronic_pod ? RouteNamesEnum.ViewElectronicPod : RouteNamesEnum.ViewPod,
            params: { id: currentEvent?.id }
          });
        })
    }
    
    if(currentEvent?.role === JobGroupsEnum.JobPosted && currentEvent?.entity === EventRedirectTypesEnum.Invoice) {
      router.push({
        name: RouteNamesEnum.ViewInvoice,
        params: { id: currentEvent?.id },
        query: { role: 'poster'}
      });
    }
  
    if(currentEvent?.role === JobGroupsEnum.MyWork && currentEvent?.entity === EventRedirectTypesEnum.Invoice) {
      router.push({
        name: RouteNamesEnum.ViewInvoice,
        params: { id: currentEvent?.id }
      });
    }
  
    if(currentEvent?.role === JobGroupsEnum.JobPosted && currentEvent?.entity === EventRedirectTypesEnum.Job) {
      router.push({
        name: RouteNamesEnum.OpenJob,
        params: { id: currentEvent?.id }
      });
    }
  }
}