import { useToast } from "vue-toastification";
import { updateDbUserLocation } from "./updateFirebaseUserLocation";
import { isPlatform } from "@ionic/core";
import { Geolocation } from '@capacitor/geolocation';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import StorageService from "@/helpers/storageService";
import { User } from "@/gql/graphql";

const getStatus = async (): Promise<AppTrackingStatusResponse> => {
  const response = await AppTrackingTransparency.getStatus();

  return response;
}

let updateFirebaseLocationIntervalId: any;
let permissionStatus: any;

const updatePosition = async (user: User, withVehicle: boolean) => {
  if(!user?.is_impersonated) {
    const permissionStatus: any = isPlatform('capacitor') ? await Geolocation.checkPermissions() : await navigator?.permissions?.query({ name: 'geolocation' });
    const intervalInMs = withVehicle ? process.env.VUE_APP_UPDATE_LOCATION_INTERVAL : process.env.VUE_APP_UPDATE_LOCATION_INTERVAL_WITHOUT_VEHICLE;
    if ((isPlatform('capacitor') && permissionStatus?.location === 'granted') || (navigator.geolocation && permissionStatus?.state === 'granted')) {
      updateDbUserLocation(user, true)
      updateFirebaseLocationIntervalId = setInterval(
        updateDbUserLocation.bind(null, user, true),
        intervalInMs
      );
      StorageService.setItem('isPositionWatching', true)
    } else {
      const toast = useToast();
      toast.error('Enable location permission in your browser to start update geo position');
      StorageService.setItem('isPositionWatching', false)
    }
  }
}

const clearUpdatePosition = async (user: User) => {
  if(!user?.is_impersonated) {
    const isIosGotPermission = isPlatform('ios') && (await getStatus()).status === 'authorized';

    if (isIosGotPermission || isPlatform('android')) {
      permissionStatus = await Geolocation.requestPermissions();
    }
    else {
      permissionStatus = await navigator?.permissions?.query({ name: 'geolocation' });
    }
    StorageService.setItem('isPositionWatching', false)
    StorageService.setItem('currentPosition', '');

    clearInterval(updateFirebaseLocationIntervalId);
  }
}

export { clearUpdatePosition, updatePosition }