import { User } from "@/gql/graphql"

export const isAbleToDo = (user: User, ability: string) => {
  if(!user?.profileSettings?.length) {
    return false
  } else if (!user?.profileSettings?.find(s => s?.setting?.code === ability)) {
    return false
  } else {
    return user?.profileSettings?.find(s => s?.setting?.code === ability)?.value
  }
}
