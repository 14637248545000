import gql from 'graphql-tag';
import { UserFragment } from '@/graphql/fragments/user';

export const OnFlightChanges = gql`
  subscription OnFlightChanges($userID: ID!) {
    onFlightChanges(user_id: $userID) {
      type
      event {
        id
        user_id
        name
        description
        status
        type
        group
        created_at
        updated_at
        pretty_date
        redirect_entity
        redirect_id
      }
      maintenance_date
      user {
        ...UserFields
      }
      message {
        id
        body
        participation {
          id
          messageable {
            id
            avatar_url
            name_first
            name_last
          }
          conversation {
            id
            job_id
          }
        }
        created_at
        notifications {
          is_seen
          messageable_id
          is_sender
        }
      }
    }
  }
  ${UserFragment}
`
