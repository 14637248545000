import gql from 'graphql-tag';

export const GetDocumentsByType = gql`
  query GetDocumentsByType($type_id: ID!, $filters: DocumentFilters) {
    getDocumentsByType(type_id: $type_id, filters: $filters) {
      id
      status
      rejection_reason
      created_at
      uploads {
        id
        path
        path_url  
        document_id
      }
      amount
      insured_amount
      expiry
      user {
        id  
        name_first
        name_last  
      }
      type {
        id
        name
        amount_required
        expiry_required
      }
    }
  }
`
