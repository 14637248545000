import { createApp, provide, h } from 'vue';
import App from './App.vue';
import AuthModule from './modules/Auth/Auth';
import GeneralModule from './modules/General/General';
import router from './router';
import mitt from "mitt";

import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { initializeApollo } from "@/apollo";
import '@stripe/stripe-js';

import VuePdf from 'vue3-pdfjs';

import { IonicVue } from '@ionic/vue';

import OneSignalHandler from '@/ts/onesignal';
import * as Sentry from "@sentry/vue";
import TawkMessengerVue from 'tawk-messenger-vue-3-ts';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.scss';

import Toast, { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { isMobile } from './helpers/isMobile';

const emitter = mitt();

const OneSignal = new OneSignalHandler();
const options: PluginOptions = {
  // You can set your default options here
};

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

document.addEventListener("deviceready", OneSignal.init, false);

const app = createApp({
  setup() {
    provide(DefaultApolloClient, initializeApollo());
  },

  render: () => h(App),
})
  .use(IonicVue, {
    mode: 'md',
  })
  .use(router)
  .use(pinia)
  .use(VuePdf)
app.use(Toast, options);
app.use(AuthModule as any);
app.use(GeneralModule as any);

if(!isMobile.value) {
  app.use(TawkMessengerVue, {
    propertyId : '63ea0f714742512879130510',
    widgetId : '1hhuetvvm'
  })
}

Sentry.init({
  app,
  environment: process.env.VUE_APP_SENTRY_ENV,
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    })
  ],
  ignoreErrors: [
    'The user credentials were incorrect.', // unsuccessful login
    'Enable location permission for your device to start update geo position', // location permissions disabled
    'Location services are not enabled', // location permissions disabled
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.provide("emitter", emitter);

router.isReady().then(() => {
  app.mount('#app');
});
