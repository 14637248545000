const haversineDistance = (prevCoords: any, curCoords: any) => {
    // Radius of the Earth in meters
    const earthRadius = 6371000; // 6,371 km

    // Convert latitude and longitude from degrees to radians
    const lat1Rad = (prevCoords.latitude * Math.PI) / 180;
    const lon1Rad = (prevCoords.longitude * Math.PI) / 180;
    const lat2Rad = (curCoords.latitude * Math.PI) / 180;
    const lon2Rad = (curCoords.longitude * Math.PI) / 180;

    // Haversine formula
    const dLat = lat2Rad - lat1Rad;
    const dLon = lon2Rad - lon1Rad;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate the distance in meters
    const distance = earthRadius * c;

    return distance;
}

export default haversineDistance;