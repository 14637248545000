export default {
  getUser () {
    return (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')  || '') : null );
  },
  setUser (value: any) {
    if (value) {
      localStorage.setItem('user', JSON.stringify(value));
        return true;
    } else { 
      return false; 
    }
  },
  getItem (key: string, type = 'ls'){
    if ((type === 'ls' ? localStorage : sessionStorage).getItem(key)) {
      try {
        return JSON.parse((type === 'ls' ? localStorage : sessionStorage).getItem(key)  || '')
      } catch {
        return (type === 'ls' ? localStorage : sessionStorage).getItem(key)
      }
    } else {
      return null;
    }
  },
  setItem (key: string, value: any, type = 'ls') {
    if (key && value) {
      typeof value != "string" ? (type === 'ls' ? localStorage : sessionStorage).setItem(key, JSON.stringify(value)) : (type === 'ls' ? localStorage : sessionStorage).setItem(key, value);
      return true;
    } else { 
      return false; 
    }
  },
  removeItem (key: string, type = 'ls') {
    if (key) {
      (type === 'ls' ? localStorage : sessionStorage).removeItem(key);
      return true;
    } else {
      return false;
    }        
  },
  clearAll () {
    localStorage.clear();
    sessionStorage.clear();
    return true;
  }
}