import { User } from '@/gql/graphql';
import StorageService from '@/helpers/storageService';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: StorageService.getUser(),
  }),
  actions: {
    updateUserStore(user: User) {
      this.user = {...user};
    },
  },
});
