import { Geolocation } from '@capacitor/geolocation';
import { isPlatform } from "@ionic/core";

const getCurrentPosition = () => {
  return isPlatform('capacitor')
    ? Geolocation.getCurrentPosition()
    : new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    );
};

export { getCurrentPosition };
