import { ApolloLink, Observable, NextLink, Operation, FetchResult } from "apollo-link";
import StorageService from "@/helpers/storageService";

interface PusherLinkOptions {
  pusher: any; // Replace with the appropriate Pusher type
}

class PusherLink extends ApolloLink {
  pusher: any; // Replace with the appropriate Pusher type

  constructor(options: PusherLinkOptions) {
    super();

    this.pusher = options.pusher;
  }

  request(operation: Operation, forward?: NextLink): Observable<FetchResult> | null {

    const token = StorageService.getItem('access_token');

    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
    const subscribeObservable = new Observable<FetchResult>(() => {
      // ...
    });
    
    const prevSubscribe = subscribeObservable.subscribe.bind(subscribeObservable);

    subscribeObservable.subscribe = (observerOrNext, onError?, onComplete?) => {
      prevSubscribe(observerOrNext, onError, onComplete);

      const observer = getObserver(observerOrNext, onError, onComplete);
      let subscriptionChannel: string | null;

      forward?.(operation)?.subscribe({
        next: (data: FetchResult) => {
          subscriptionChannel =
            data?.extensions?.lighthouse_subscriptions.channel ?? null;

          if (!subscriptionChannel) {
            observer.next(data);
            observer.complete();
            return;
          }

          this.subscribeToChannel(subscriptionChannel, observer);
        },
      });

      return {
        closed: false,
        unsubscribe: () => {
          subscriptionChannel && this.unsubscribeFromChannel(subscriptionChannel);
        },
      };
    };

    return subscribeObservable;
  }

  subscribeToChannel(subscriptionChannel: string, observer: any) {
    this.pusher
      .subscribe(subscriptionChannel)
      .bind("lighthouse-subscription", (payload: any) => {
        if (!payload.more) {
          this.unsubscribeFromChannel(subscriptionChannel);
          observer.complete();
        }

        const result = payload.result;
        if (result) {
          observer.next(result);
        }
      });
  }

  unsubscribeFromChannel(subscriptionChannel: string) {
    this.pusher.unsubscribe(subscriptionChannel);
  }
}

function getObserver(observerOrNext: any, onError?: any, onComplete?: any) {
  if (typeof observerOrNext === "function") {
    return {
      next: (v: any) => observerOrNext(v),
      error: (e: any) => onError && onError(e),
      complete: () => onComplete && onComplete(),
    };
  } else {
    return {
      next: (v: any) => observerOrNext.next && observerOrNext.next(v),
      error: (e: any) => observerOrNext.error && observerOrNext.error(e),
      complete: () => observerOrNext.complete && observerOrNext.complete(),
    };
  }
}

export default PusherLink;