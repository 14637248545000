import gql from 'graphql-tag';
import { UserFragment } from '@/graphql/fragments/user';

export const EditProfile = gql`
  mutation EditProfile($input: EditProfileInput!) {
    editProfile(input: $input) {
      ...UserFields
    }
  }
  ${UserFragment}
`