import { RouteNamesEnum } from "@/const/routes";
import { computed } from "vue";
import { checkLocationPermissions, getIosTrackingPermission, getCapacitorGeoLocationPermission } from "./requestLocationPermissions";
import { modalId, setModalId } from "./setPermissionsModal";
import { startupTrackingUpdate, deactivateFirebaseUser } from "./updateFirebaseUserLocation";
import { useRoute } from "vue-router";
import { ProfileSettingsEnum, User } from "@/gql/graphql";
import { isAbleToDo } from "./isAbleToDo";

const route = useRoute();
const isSmallModal = computed(() => route?.name !== RouteNamesEnum.Notifications || modalId.value === 3 || modalId.value === 4);

export const handleFirebaseUserTracking = async (user: User) => {
  await checkLocationPermissions(isSmallModal.value);
  const iosPermission = await getIosTrackingPermission();
  const capacitorGeoPermission = await getCapacitorGeoLocationPermission();

  if (iosPermission === 'authorized' && capacitorGeoPermission === 'granted') {
    if(modalId.value) {
      setModalId(0);
    }
    if (isAbleToDo(user, ProfileSettingsEnum.Tracking)) {
      startupTrackingUpdate(user);
    }
  } else {
    deactivateFirebaseUser(user)
  }
};