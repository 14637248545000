import PusherLink from '@/modules/EventSubscription/pusherSubscriber';
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import Pusher from 'pusher-js';
import { ref } from 'vue';
import { onError } from '@apollo/client/link/error';
import { useToast } from 'vue-toastification';
import { RouteNamesEnum } from "@/const/routes";
import router from "@/router";
import StorageService from "@/helpers/storageService";
import { User } from '@/gql/graphql';

let apolloClient: ApolloClient<any>;
const receivedEvent = ref();
const receivedMaintenanceStart = ref();
const receivedMaintenanceEnd = ref();
const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url =
        'responseURL' in xhr
          ? xhr.responseURL
          : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.onProgress) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const authLink = new ApolloLink((operation, forward) => {
  const token = StorageService.getItem('access_token');
  // will be redone

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(operation);
});

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API_URL,
  credentials: 'same-origin',
  fetch: customFetch as any,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const toast = useToast();
  const user: User = StorageService.getUser();
  if (graphQLErrors) {
    if (graphQLErrors?.[0]?.extensions?.validation) {
      const errors = graphQLErrors?.[0]?.extensions?.validation;
      Object.keys(errors).forEach((key) => {
        errors[key].forEach((error: any) => {
          toast.error(error);
        });
      });
      return;
    }
    graphQLErrors.forEach(({ message }) => {
      if (message == 'Access denied. The team is deactivated.' && !user?.is_impersonated) {
        router.push({name: RouteNamesEnum.Attention, query: {type: 'team'}});
      } else if (message == 'This action is unauthorized.' && !user?.is_impersonated) {
        router.push({name: RouteNamesEnum.ActionUnauthorized});
      } else {
        toast.error('GraphQL Error: ' + message);
      }
    });
  }

  if (networkError) {
    if(networkError?.message?.includes('Response not successful: Received status code 503')) {
      router.push({name: RouteNamesEnum.Maintenance});
    } else {
      toast.error('Network Error: ' + networkError.message);
    }
  }
});

window.onerror = (error) => {
  if(error?.toString()?.includes('Response not successful: Received status code 503')) {
    router.push({name: RouteNamesEnum.Maintenance});
  }
}


const pusherKey = process.env.VUE_APP_PUSHER_APP_KEY;
const pusherCluster = process.env.VUE_APP_PUSHER_APP_CLUSTER;

const pusherLink = new PusherLink({
  pusher: new Pusher(pusherKey, {
    cluster: pusherCluster,
    authEndpoint: `${process.env.VUE_APP_API_URL}/subscriptions/auth`,
    auth: {
      headers: {
        authorization: StorageService.getItem('token'),
      },
    },
  }),
});

const createApolloClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([errorLink, pusherLink as any as ApolloLink, authLink, httpLink]), // doesn't not work - errorLink 503
    // link: ApolloLink.from([errorLink.concat(httpLink), pusherLink as any as ApolloLink, authLink]), // doesn't not work - Subscription error: ApolloError: Unauthorized subscription request
    cache: new InMemoryCache({}),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
};

const initializeApollo = () => apolloClient ?? createApolloClient();

export { initializeApollo, receivedEvent, receivedMaintenanceStart, receivedMaintenanceEnd };
