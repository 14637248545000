<template>
  <div class="base-spinner">
    <div class="base-spinner__inner"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.base-spinner {
  background: var(--ion-color-primary);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 7.5px solid var(--ion-color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  &__inner {
    width: 17.5px;
    height: 17.5px;
    border-radius: 50%;
    background: var(--ion-color-white);
    position: relative;
    transform: rotate(-45deg);
    animation: rotate 2s linear infinite;
    &::after {
      position: absolute;
      content: '';
      display: inline-block;
      background: var(--ion-color-white);
      width: 7px;
      height: 23px;
      top: -2px;
      left: -3px;
    }
  }

  &.ion-background-color {
    border-color: var(--ion-background-color);

    .base-spinner__inner, .base-spinner__inner::after {
      background: var(--ion-background-color);
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
