import { ProfileSettingsEnum } from "@/gql/graphql";
import { isAbleToDo } from "@/helpers/isAbleToDo";
import { getCapacitorGeoLocationPermission, getIosTrackingPermission } from "@/helpers/requestLocationPermissions";
import { updatePosition } from "@/helpers/tracking";
import { isPlatform } from "@ionic/vue";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { computed } from "vue";
import { useUserStore } from "@/modules/General/store/userStore";

const checkPermissionsToStartUpdateLocation = ref();
const isToastSeen = ref(false);

const checkSystemLocationPermissionsAndRunUpdateLocation = async () => {
  const user = computed(() => (useUserStore() as any).user);
  if(!user.value?.is_impersonated) {

    const iosPermission = await getIosTrackingPermission();
    const capacitorGeolocationPermission = await getCapacitorGeoLocationPermission();
    const navigatorPermission = await navigator?.permissions?.query({ name: 'geolocation' });
    if(isPlatform('desktop')) {
      if(navigatorPermission?.state === 'prompt') {
        new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)).catch((error) => {
          if (error.code === error.PERMISSION_DENIED && !isToastSeen.value) {
            const toast = useToast();
            toast.error('Enable location permission in your browser to start update geo position')
            isToastSeen.value = true;
          } else {
            console.error("Error getting location: " + error.message);
          }
        })
      } else if(navigatorPermission?.state === 'denied' && !isToastSeen.value) {
        const toast = useToast();
        toast.error('Enable location permission in your browser to start update geo position')
        isToastSeen.value = true;
      }
    }
    
    const gotPermission = isPlatform('capacitor')
    ? iosPermission === 'authorized' && capacitorGeolocationPermission === 'granted'
    : (navigator.geolocation && navigatorPermission?.state === 'granted');
    
    if (
      isAbleToDo(user.value, ProfileSettingsEnum.Tracking)
      && gotPermission
    ) {
      console.log('all permissions got and able to track, starting to update');
      updatePosition(user.value, !!user.value?.vehicle);
      clearSystemPermissionsCheck();
    }
  }
}

const clearSystemPermissionsCheck = () => {
  clearInterval(checkPermissionsToStartUpdateLocation.value);
}

const runIntervalForSystemPermissionCheck = () => {
  checkPermissionsToStartUpdateLocation.value = setInterval(
    checkSystemLocationPermissionsAndRunUpdateLocation,
    1000
  )
};

export { checkSystemLocationPermissionsAndRunUpdateLocation, clearSystemPermissionsCheck, runIntervalForSystemPermissionCheck }