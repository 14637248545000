import { isPlatform } from "@ionic/core";
import { Geolocation } from '@capacitor/geolocation';

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { setModalId } from "./setPermissionsModal";

const getStatus = async (): Promise<AppTrackingStatusResponse> => {
  const response = await AppTrackingTransparency.getStatus();
  return response;
}

const requestPermission = async (): Promise<AppTrackingStatusResponse> => {
  const response = await AppTrackingTransparency.requestPermission();
  return response;
}

const checkLocationPermissions = async (isSmallModal: boolean, withModal = true) => {
  let result = '';
  if (isPlatform('ios')) {
    await getStatus()
      .then(async (response) => {
        if (response.status === 'denied') {
          result = 'denied';
          if(withModal) {
            setModalId(isSmallModal ? 4 : 2);
          }
          return result;
        } else if (response.status === 'notDetermined') {
          result = 'notDetermined';
          if(withModal) {
            setModalId(isSmallModal ? 3 : 1);
          }
          return result;
        } else if (response.status === 'authorized') {
          result = 'authorized';
        }
      });
  }

  if (isPlatform('capacitor')) {
    await Geolocation.checkPermissions()
      .then((response) => {
        if (response.location === 'granted') {
          result = 'granted';
        } else if (response.location === 'prompt') {
          result = 'prompt';
          if(withModal) {
            setModalId(isSmallModal ? 3 : 1);
          }
        } else if (response.location === 'denied' || response.location === 'prompt-with-rationale') {
          result = 'denied';
          if(withModal) {
            setModalId(isSmallModal ? 4 : 2)
          }
        }
      })
  }
  return result
};

const requestLocationPermissions = async (isSmallModal: boolean) => {
  let result = '';
  let iosStatus = ''
  let capacitorGeolocationStatus = '';
  if (isPlatform('ios')) {
    await requestPermission()
      .then((response) => {
        if (response.status === 'authorized') {
          result = 'authorized';
          iosStatus = 'authorized';
        } else if (response.status === 'denied') {
          result = 'denied';
          iosStatus = 'denied';
          checkLocationPermissions(isSmallModal);
          return result;
        } else if (response.status === 'notDetermined') {
          result = 'notDetermined';
          iosStatus = 'notDetermined';
          checkLocationPermissions(isSmallModal);
          return result;
        }
      })
  }

  if (isPlatform('capacitor')) {
    await Geolocation.requestPermissions()
      .then((response) => {
        if (response.location === 'granted') {
          result = 'granted';
          capacitorGeolocationStatus = 'authorized';
          if (iosStatus !== 'authorized') {
            checkLocationPermissions(isSmallModal);
          }
        } else if (response.location === 'prompt') {
          result = 'prompt';
          capacitorGeolocationStatus = 'prompt';
          checkLocationPermissions(isSmallModal);
        } else if (response.location === 'denied' || response.location === 'prompt-with-rationale') {
          result = 'denied';
          capacitorGeolocationStatus = 'denied';
          checkLocationPermissions(isSmallModal);
        }
      })
  }
  return result
}

const getIosTrackingPermission = async () => {
  if(isPlatform('ios')) {
    const response = await AppTrackingTransparency.getStatus();
    return response.status;
  }
  return 'authorized';
}

const getCapacitorGeoLocationPermission = async () => {
  let result = '';
  await Geolocation.checkPermissions()
    .then((response) => {
      if (response.location === 'granted') {
        result = 'granted';
      } else if (response.location === 'prompt') {
        result = 'prompt';
      } else if (response.location === 'denied') {
        result = 'denied';
      }
    })
  return result;
}

const getIsWrongPermissions = async () => {
  const iosPermission = isPlatform('ios') ? await getIosTrackingPermission() : 'authorized';
  const capacitorGeolocationPermission = await getCapacitorGeoLocationPermission();
  const navigatorPermission = await navigator?.permissions?.query({ name: 'geolocation' })

  return isPlatform('capacitor')
    ? iosPermission !== 'authorized' && capacitorGeolocationPermission !== 'granted'
    : (navigator.geolocation && navigatorPermission?.state !== 'granted');
}

export { getIosTrackingPermission, getCapacitorGeoLocationPermission, checkLocationPermissions, requestLocationPermissions, getIsWrongPermissions, requestPermission };
